.app-header {
  background-color: $dark-theme;
}
.app-sidebar__toggle span {
  background: $secondary;
  color: $secondary-text-color
}
.notification .notification_circle{
  background-color: $secondary-text-color;
  color: $secondary;
}
.app-sidebar {
  background-color: $dark-theme;
}
.app-menu__item {
  color: $side-nav-text;
}
.app-menu__item.active, .app-menu__item:hover, .app-menu__item:focus{
  background-color: $secondary !important;
  color: $secondary-text-color !important;
}
.app_title {
  color: $secondary-text-color;
}
.app_title a{
  //color: $secondary;
}
.left_sidebar_Content {
  background: $secondary;
}
.app-sidebar .app-sidebar_Heading{
  color: $side-nav-text;
}
.underline {
  //border-bottom: solid 1px $color-primary;
  //padding-bottom: 3px;
  color: $secondary-text-color;
}
.underline:hover {
  opacity: 0.3;
}
.left_sidebar_Content .top_user ul li {
  span, a {
    background: $secondary-icn-bg;
    color: $side-nav-text;
  }
}
.app-menu__item .icons_white {
  display: inline-block;
}
.app-menu__item .icons_blue {
  display: none;
}
//.app-menu__item.active .icons_white, .app-menu__item:hover .icons_white, .app-menu__item:focus .icons_white{
//  display: inline-block;
//}
//.app-menu__item.active .icons_blue, .app-menu__item:hover .icons_blue, .app-menu__item:focus .icons_blue {
//  display: none;
//}
.card-header {
  background: $secondary;
  .card-title {
    color: $secondary-text-color;
  }
}
.btn-theme-primary {
  background-color: $secondary-text-color;
  color:  $dark-theme;
}
@media (min-width: 768px) {
  .sidebar-mini.sidenav-toggled .app-menu__label {
    background: $secondary;
  }
}
@media(max-width: 991px){
  .mobile_toggle_right i {
    color: $secondary-text-color;
  }
  .mobile_toggle_right i:hover,
  .mobile_toggle_right i:focus{
    color: $secondary-text-color;
  }
  .left_sidebar_wrapper .cross-icon {
    background: $secondary;
    color: $secondary-text-color;
  }
}
