//@extend-elements
//original selectors
//input[type=checkbox], input[type=radio]
%extend_1 {
  margin: 1px 0 0;
  line-height: normal;
  box-sizing: border-box;
  padding: 0;
}

//original selectors
//.btn-wait:after, .btn-wait:before
%extend_2 {
  content: '';
  position: absolute;
  width: 24px;
  height: 24px;
  top: calc(50% - 12px);
  left: 32%;
  opacity: 1;
}

body {
  background: #f1f4f5;
  color: #676767;
  font-family: $font-family-base !important;
}

.limiter {
  width: 100%;
  float: left;
  margin: 0 auto;
  .container-login100 {
    width: 100%;
    float: left;
    .login100-form {
      padding: 63px 0 0;
      position: absolute;
      right: 0;
      width: 38%;
      .step-info {
        font-size: 12px;
        color: #999;
        margin: 12px 0 0;
      }
    }
    #signup_form {
      padding: 32px 0 0;
      width: 100%;
      .inner_form {
        max-width: 700px;
        h1 {
          font-size: 38px;
          margin-bottom: 30px;
          text-align: center;
        }
        .form_group_modify {
          margin-bottom: 8px;
        }
      }
      .fields {
        max-width: 360px;
        margin: 0 auto;
      }
      .theme_btn {
        &.btn-wait::before {
          left: 70px;
        }
        &.btn-wait::after {
          left: 70px;
        }
      }
      .step-info {
        padding-top: 30px;
      }
    }
    .login100-more {
      position: fixed;
      z-index: 5;
      right: 38%;
      top: 0;
      bottom: 0;
      float: left;
      left: 0;
      //padding: 50px 40px 40px;
      background: rgba(135, 152, 180, .2);
      .img-login {
        width: 100%;
        margin: 0 0 0 0;
        height: 100vh;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .foot-note {
        margin: 40px 0 0;
        color: #31323c;
        text-align: center;
        h3 {
          margin: 0 0 8px;
          font-size: 23px;
          font-weight: 400;
        }
        span {
          font-size: 16px;
          font-weight: 300;
        }
        .btn-line {
          text-align: center;
          border: 1px solid rgba(0, 0, 0, .15);
          color: #666;
          background: transparent;
          padding: 10px 13px;
          box-shadow: none;
          text-shadow: none;
          &:hover {
            background: #525252;
            color: $white;
            box-shadow: 0 2px 3px 0 rgba(0, 0, 0, .22);
          }
        }
      }
    }
  }
  .inner_form {
    width: 75%;
    max-width: 400px;
    min-height: 500px;
    margin: 40px auto 0;
    h1 {
      font-size: 42px;
      font-weight: 300;
      margin: 0 0 15px;
      white-space: normal;
      overflow: visible;
      text-overflow: inherit;
    }
    h2 {
      font-size: 16px;
      font-weight: 300;
      color: #999;
      margin: 0 0 60px;
      line-height: normal;
    }
    .btn_lg {
      min-width: 183px;
      margin-bottom: 30px;
      font-size: 20px;
      padding: 18px 38px;
      font-weight: 400;
      letter-spacing: 1px;
    }
  }
  #signup-header {
    padding: 12px 0;
    width: 100%;
    float: left;
    position: relative;
    z-index: 10;
    &.no-back {
      background: $white;
      border-bottom: 1px solid #eee;
    }
  }
  .login-logo {
    display: block;
    width: 100%;
    text-align: center;
    a {
      display: block;
      margin: 0 auto;
    }
  }
  .signup_header.no-back {
    background: #eee !important;
  }
}

.form_group_modify {
  margin-bottom: 20px;
}

.label_modify {
  margin-bottom: 8px;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600;
  padding: 0;
  text-align: left;
  display: inline-block;
  max-width: 100%;
}

.input_modify_lg {
  font-size: 15px;
  height: 50px;
}

.input_modify {
  color: #3b3b3b;
  width: 100%;
  height: 50px;
  background: $white;
  border-radius: 2px;
  padding: 10px 16px;
  font-size: 15px;
  line-height: 1.33;
  display: block;
  border: 1px solid #ccc;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, .12);
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  letter-spacing: 0.3px;
}

.custom_check {
  position: relative;
  display: block;
  min-height: 22px;
  margin: 10px 0;
  label {
    color: #31323c;
    cursor: pointer;
    vertical-align: middle;
    font-size: 14px;
    font-weight: 400;
    text-transform: none;
    padding: 0 0 0 32px;
  }
  > input {
    opacity: 0;
    position: absolute;
    + label {
      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 22px;
        height: 22px;
        background: $white;
        box-shadow: inset 0 1px 2px 2px rgba(0, 0, 0, .07), inset 0 0 0 1px #b7b7b7;
        transition: all .2s linear;
        display: inline-block;
        vertical-align: middle;
      }
      &:after {
        content: "";
        position: absolute;
        opacity: 0;
        transition: all .3s linear;
      }
    }
    &.checked + label:after {
      opacity: 1;
    }
    &:checked + label:after {
      opacity: 1;
    }
  }
  > input[type=checkbox] + label {
    &:before {
      border-radius: 2px;
    }
    &:after {
      width: 22px;
      height: 22px;
      top: 0;
      left: 0;
      background: image_url('checkbox.png') 50% no-repeat;
      background-size: 22px;
    }
  }
}

input[type=checkbox] {
  @extend %extend_1;
}

input[type=radio] {
  @extend %extend_1;
}

.theme_btn {
  border: 0;
  border-radius: 4px;
  outline: none;
  color: $white;
  font-size: 13px;
  font-weight: 600;
  text-transform: capitalize;
  padding: 11px 14px;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, .2);
  transition: all .2s ease-in-out;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, .15);
  letter-spacing: .2px;
  line-height: 1.33;
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background: $primary none;
  &:hover {
    background-color: #0065ff;
    border-color: #0065ff;
    color: $white;
  }
}

.grey {
  color: #666 !important;
  color: #3b3b3b !important;
}

.grey6 {
  color: #666 !important;
  color: #3b3b3b !important;
}

a {
  &.grey6:visited {
    color: #666 !important;
    color: #3b3b3b !important;
  }
  &.grey:visited {
    color: #666 !important;
    &:hover {
      color: #3b3b3b !important;
    }
  }
}

.btn-wide {
  padding-left: 30px;
  padding-right: 30px;
  width: 10em;
  &.btn-wait {
    padding-left: 12px;
  }
}

.btn-wait {
  position: relative;
  background: no-repeat calc(100% - 12px) 6px;
  &:after {
    @extend %extend_2;
    background-image: url("data:image/svg+xml;charset=UTF-8,%EF%BB%BF%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Ccircle cx='12' cy='12' r='10.186' fill='none' opacity='.7' stroke='white' stroke-width='3.8' stroke-dasharray='1 6 1 20 1 13' stroke-linecap='round' /%3E%3C/svg%3E");
    animation: spin 2.3s linear infinite;
  }
  &:before {
    @extend %extend_2;
    background-image: url("data:image/svg+xml;charset=UTF-8,%EF%BB%BF%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Ccircle cx='12' cy='12' r='10.186' opacity='.7' fill='none' stroke='white' stroke-width='3.8' stroke-dasharray='1 31 1 11 1 40' stroke-linecap='round' /%3E%3C/svg%3E");
    animation: spin 1s linear infinite;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.form-error {
  margin-top: 4px;
  font-size: 12px;
}

.checkboxes {
  margin: 27px 0 22px;
}

.w-100 {
  width: 100%;
}

.center {
  text-align: center;
}

.input_modify::placeholder {
  color: #ccc;
}

.input_modify::-webkit-input-placeholder {
  color: #ccc;
}

.input_modify::-moz-placeholder {
  color: #ccc;
}

.or-div {
  text-align: center;
  color: #ddd;
  font-weight: 600;
  font-size: 12px;
  position: relative;
  margin: 25px 0;
  &:before {
    content: "";
    position: absolute;
    top: 8px;
    width: calc(45% - 20px);
    height: 1px;
    background-color: #ddd;
    left: 20px;
  }
  &:after {
    content: "";
    position: absolute;
    top: 8px;
    width: calc(45% - 20px);
    height: 1px;
    background-color: #ddd;
    right: 20px;
  }
}

.mb_0 {
  margin-bottom: 0 !important;
}

.btn-google {
  width: 230px;
  max-width: 100%;
  border-color: $primary !important;
  color: $primary !important;
  text-align: center;
  border: 1px solid rgba(0, 0, 0, .15);
  background: transparent;
  padding: 16px 13px;
  box-shadow: none;
  border-radius: 4px;
  font-size: 13px;
  font-weight: 600;
  text-transform: capitalize;
  transition: all .2s ease-in-out;
  letter-spacing: 1px;
  cursor: pointer;
  text-shadow: none;
  &:hover {
    background-color: #0065ff !important;
    color: $white !important;
    border: 1px solid transparent;
  }
}

.btn_disabled[disabled] {
  opacity: .2;
  pointer-events: auto;
  cursor: no-drop;
}

.icon-caret {
  display: inline-block;
  speak: none;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  text-align: center;
  margin-left: 8px;
  font-size: 20px;
}

.icon-google {
  display: inline-block;
  speak: none;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  text-align: center;
  margin-right: 8px;
}

.post_rltv {
  position: relative;
}

.lo-W-All {
  float: left;
  width: 100%;
  margin-top: 15px;
  .underline {
    display: inline-block;
    margin-left: 10px;
    color: $primary !important;
    border-bottom: solid 1px $primary;
    padding: 0;
  }
}

.lo_All {
  float: left;
  width: 100%;
  h1 {
    float: left;
    width: 100%;
    color: #172b4d;
  }
  h2 {
    float: left;
    width: 100%;
    margin: 10px 0 30px;
    a.underline {
      display: inline-block;
      margin-left: 10px;
      color: $primary !important;
      border-bottom: solid 1px $primary;
      padding: 0;
    }
  }
}

@media only screen and(max-width: 1680px) {
  .limiter .container-login100 .wrap-login100 .login100-more .img-login {
    height: auto;
  }
}

@media only screen and(max-width: 1280px) {
  .limiter .container-login100 {
    .login100-more {
      padding: 150px 40px 40px;
    }
    .login100-form {
      top: 30px;
    }
  }
}

@media only screen and(max-width: 991px) {
  .limiter .container-login100 {
    .wrap-login100 .login100-more {
      padding: 120px 40px 40px;
    }
    .login100-more .foot-note {
      margin: 25px 0 0;
      h3 {
        font-size: 20px;
        line-height: 28px;
      }
    }
  }
}

@media only screen and(max-width: 767px) {
  .limiter {
    .inner_form {
      max-width: 420px;
      margin: 30px auto 30px;
      h2 {
        margin: 0 0 30px;
      }
      h1 {
        font-size: 34px;
      }
    }
    .container-login100 .wrap-login100 {
      .login100-form {
        width: 100%;
      }
      .login100-more {
        display: none;
      }
      #signup_form .inner_form h1 {
        font-size: 30px;
      }
    }
  }
  .form_group_modify {
    margin-bottom: 15px;
  }
}

@media only screen and(max-width: 575px) {
  .limiter {
    .container-login100 {
      .wrap-login100 {
        .login100-form {
          height: 100%;
        }
        #signup_form {
          padding: 0;
          .inner_form h1 {
            font-size: 26px;
          }
        }
      }
      #signup_form .inner_form h1 {
        font-size: 38px;
        margin-top: 50px;
      }
    }
    .inner_form {
      width: 90%;
      height: 100%;
      h1 {
        font-size: 30px;
      }
    }
  }
}

@media only screen and(max-width: 480px) {
  .limiter .container-login100 .wrap-login100 #signup_form .inner_form h1 {
    font-size: 24px;
    line-height: 33px;
  }
}

@media only screen and(max-width: 430px) {
  .limiter .container-login100 .wrap-login100 #signup_form .theme_btn {
    &.btn-wait::before {
      left: 55px;
    }
    &.btn-wait::after {
      left: 55px;
    }
  }
}

@media only screen and(max-width: 399px) {
  .limiter .container-login100 .wrap-login100 #signup_form .theme_btn {
    &.btn-wait::before {
      left: 35px;
    }
    &.btn-wait::after {
      left: 35px;
    }
  }
}

@media only screen and(max-width: 325px) {
  .limiter .container-login100 .wrap-login100 #signup_form .theme_btn {
    &.btn-wait::before {
      left: 28px;
    }
    &.btn-wait::after {
      left: 28px;
    }
  }
}

button:focus {
  outline: none !important;
}

.inner_form .theme_btn {
  &.btn-wait::before {
    left: 8px;
  }
  &.btn-wait::after {
    left: 8px;
  }
}

.btn_disabled.disabled {
  opacity: .2;
  pointer-events: auto;
  cursor: no-drop;
}
.inner_content .MuiCard-root{
overflow:visible;
}
.react-datepicker-popper{
z-index:9!important
}
.react-datepicker__input-container input {
  font: inherit;
  color: currentColor;
  width: 100%;
  border: 0;
  height: 1.1876em;
  margin: 0;
  display: block;
  padding: 6px 0 7px;
  min-width: 0;
  background: none;
  box-sizing: content-box;
  animation-name: mui-auto-fill-cancel;
  letter-spacing: inherit;
  animation-duration: 10ms;
  -webkit-tap-highlight-color: transparent;
  outline: none;
}
.react-datepicker__input-container:before {
  left: 0;
  right: 0;
  bottom: 0;
  content: "\00a0";
  position: absolute;
  transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
  pointer-events: none;
}
.react-datepicker__input-container:after {
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  position: absolute;
  transform: scaleX(0);
  transition: transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
  border-bottom: 2px solid #3f51b5;
  pointer-events: none;
}
.react-datepicker__input-container:hover::before {
  border-bottom: 2px solid rgba(0, 0, 0, 0.87);
}
.MuiInput-underline.Mui-focused:after {
  transform: scaleX(1);
}