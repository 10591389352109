.app-header {
  background: $light-theme;
}
.app-sidebar__toggle span {
  background: $primary-color;
  color: $white;
}
.notification .notification_circle{
  background: $primary-color;
  color: $white;
}
.app-sidebar {
  background: $light-theme;
}
.app-menu__item {
  color: $side-nav-text;
}
.app-menu__item.active, .app-menu__item:hover, .app-menu__item:focus{
  background: $primary-color !important;
  color: $white !important;
}
.app_title {
  color: $primary-text-color;
}
.app_title a{
  //color: $primary-color;
}
.left_sidebar_Content {
  background: $primary-color;
}
.app-sidebar .app-sidebar_Heading{
  color: $side-nav-text;
}
.underline {
  //border-bottom: solid 1px $color-primary;
  //padding-bottom: 3px;
  color: $primary-text-color !important;
}
.left_sidebar_Content .top_user ul li {
  span, a {
    background: $primary-icn-bg;
    color: $white;
  }
}
.app-menu__item .icons_white {
  display: none;
}
.app-menu__item .icons_blue {
  display: inline-block;
}
.app-menu__item.active .icons_white, .app-menu__item:hover .icons_white, .app-menu__item:focus .icons_white{
  display: inline-block;
}
.app-menu__item.active .icons_blue, .app-menu__item:hover .icons_blue, .app-menu__item:focus .icons_blue {
  display: none;
}
.card-header {
  background: $primary-color;
  .card-title {
    color: $white;
  }
}
.btn-theme-primary {
  background: $light-theme;
  color: #0747A6;
}
@media (min-width: 768px) {
  .sidebar-mini.sidenav-toggled .app-menu__label {
    background: $primary-color;
  }
}
@media(max-width: 991px){
  .mobile_toggle_right i {
    color: $primary-text-color;
  }
  .mobile_toggle_right i:hover,
  .mobile_toggle_right i:focus{
    color: $primary-text-color;
  }
  .left_sidebar_wrapper .cross-icon {
    background: $primary-color;
    color: $white;
  }
}
