//colors

$dark-theme: #262933;
$secondary-text-color: #eee;
$side-nav-text: #eee;
$secondary: #1e2129;
$secondary-icn-bg: rgba(45, 50, 62, 0.45);


// Fonts
$font-family-base: 'Poppins', sans-serif;
$font-size-base:      0.9375rem  !default; // Assumes the browser default, typically `16px`








