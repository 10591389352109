//@extend-elements
//original selectors
//.app-sidebar__toggle:focus, .app-sidebar__toggle:hover

%extend_3 {
  color: $white;
  background-color: transparent;
  text-decoration: none;
}

body {
  font-family: Roboto, sans-serif;
  font-weight: 400;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.app-nav {
  padding-left: 0;
  list-style: none;
  display: flex;
  margin-bottom: 0;
  justify-content: flex-end;
  align-items: center;
}

.app-menu {
  padding-left: 0;
  list-style: none;
  margin-bottom: 0;
  margin-top: 25px;
  height: 85%;
  overflow: auto;
  .app-menu__icon {
    margin-right: 20px;
  }
}

.treeview-menu {
  padding-left: 0;
  list-style: none;
}

.sidebar-mini {
  .app-header {
    width: calc(100% - 299px);
    margin-left: inherit;
    left: auto;
  }
  &.sidenav-toggled .app-header {
    width: calc(100% - 145px);
  }
}

.app-header {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  display: flex;
  z-index: 3;
  padding-right: 28px;
  align-items: center;
  transition: left 0.5s ease, width 0.5s ease;
  margin-left: 299px;
  border-bottom: 1px solid #cccccc;
  .app_header_block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    transition: all 0.5s ease, width 0.5s ease;
  }
}

.app-content {
  margin-left: 299px;
  min-height: calc(100vh - 50px);
  padding: 45px 0 50px;
  background-color: #f5f5f5;
  transition: margin-left 0.3s ease;
  overflow: hidden;
}

.app-header__logo {
  &:focus {
    text-decoration: none;
    color: $white;
  }
  &:hover {
    text-decoration: none;
    color: $white;
  }
}

.app-sidebar__toggle {
  padding: 0;
  font-family: fontAwesome;
  color: $white;
  line-height: 2.4;
  margin-left: 10px;
  margin-right: 10px;
  transition: background-color 0.3s ease;
  &:before {
    content: '\f0c9';
    font-size: 21px;
    display: none;
  }
  &:focus {
    @extend %extend_3;
    opacity: 0.6;
  }
  &:hover {
    @extend %extend_3;
    opacity: 0.6;
  }
  span {
    width: 25px;
    height: 25px;
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    line-height: 25px;
    border-radius: 10px;
  }
}

.app-nav__item {
  display: block;
  padding: 0 15px;
  line-height: 20px;
  color: $white;
  transition: background-color 0.3s ease;
  &:hover {
    background: $white;
    color: #f6f6f6;
  }
  &:focus {
    background: $white;
    color: #f6f6f6;
  }
}

.app-sidebar {
  width: 229px;
  overflow: auto;
  z-index: 10;
  transition: left 0.3s ease, width 0.3s ease;
  transition: left 0.5s ease, width 0.5s ease;
  height: 100vh;
  float: right;
  overflow: hidden;
  .app-sidebar_Heading {
    padding: 25px 0 0 19px;
    white-space: nowrap;
  }
  h4 {
    font-size: $-h4;
    font-weight: normal;
    margin: 0;
  }
  p {
    font-size: $para-font;
    margin-top: 10px;
    text-transform: uppercase;
    font-weight: 500;
  }
}

.app-sidebar::-webkit-scrollbar {
  width: 6px;
}

.app-sidebar::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
}

.app-menu__item {
  position: relative;
  padding: 10px;
  font-size: 14px;
  border-left: 5px solid transparent;
  -webkit-transition: border-left-color 0.3s ease, background-color 0.3s ease;
  -o-transition: border-left-color 0.3s ease, background-color 0.3s ease;
  transition: border-left-color 0.3s ease, background-color 0.3s ease;
  margin: 0 10px 4px;
  border-radius: 4px;
  white-space: nowrap;
  word-break: keep-all;
  width: auto;
  display: block;
  img {
    margin-right: 15px;
  }
  .icons_blue {
    margin-right: 5px;
    width: 30px;
  }
  .icons_white {
    width: 30px;
  }
  &.active {
    text-decoration: none;
    outline: none;
    .icons_white {
      width: 36px;
    }
  }
  &:hover {
    text-decoration: none;
    outline: none;
    .icons_white {
      width: 36px;
    }
  }
  &:focus {
    text-decoration: none;
    outline: none;
    .icons_white {
      width: 36px;
    }
  }
}

.app-menu__icon {
  flex: 0 0 auto;
}

.app-menu__label {
  white-space: nowrap;
  flex: 1 1 auto;
}

.footer_content {
  width: 100%;
  float: left;
  text-align: center;
  padding: 15px;
  z-index: 1;
  border-top: 1px solid #e7e7e7;
  background: #f5f5f5;
  position: relative;
  p {
    margin-bottom: 0;
  }
}

@media (min-width: 768px) {
  .sidebar-mini {
    &.sidenav-toggled {
      .case_acknowlst_reports {
        padding: 45px 30px 0 25px;
      }
      .app-sidebar__user-name {
        display: none;
      }
      .app-sidebar__user-designation {
        display: none;
      }
      .treeview-indicator {
        display: none;
      }
      .app-sidebar__user-avatar {
        width: 30px;
        height: 30px;
      }
      .app-content {
        margin-left: 145px;
      }
      .app-sidebar_Heading {
        visibility: hidden;
      }
      .app-sidebar {
        left: 70px;
        width: 75px;
        overflow: hidden;
        .app-menu {
          left: 70px;
          width: 100%;
          overflow: hidden;
        }
      }
      .app-header__logo {
        left: 0;
        width: auto;
        overflow: hidden;
      }
      .app_logo {
        display: none;
      }
      .app-sidebar__toggle {
        padding: 0;
        .toogle_left {
          display: none;
        }
        .toogle_right {
          display: inline-block;
        }
      }
      .app-menu__item {
        overflow: hidden;
        &:hover {
          overflow: visible;
          .app-menu__label {
            opacity: 1;
          }
          + .treeview-menu {
            visibility: visible;
          }
        }
      }
      .app-menu__label {
        display: block;
        position: absolute;
        top: 0;
        left: 66px;
        min-width: 180px;
        padding: 12px 5px 12px 20px;
        margin-left: -3px;
        line-height: 1;
        opacity: 0;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }
      .treeview {
        &:hover {
          .app-menu__item {
            overflow: visible;
            background: #0d1214;
            border-left-color: #009688;
            color: $white;
          }
          .app-menu__label {
            opacity: 1;
          }
          .treeview-menu {
            max-height: 100vh;
            opacity: 1;
            visibility: visible;
            z-index: 10;
          }
        }
        .app-menu__label {
          border-bottom-right-radius: 0;
        }
      }
      .treeview-menu {
        position: absolute;
        left: 50px;
        min-width: 180px;
        padding: 12px 0;
        opacity: 0;
        border-bottom-right-radius: 4px;
        z-index: 9;
        visibility: hidden;
        transition: visibility 0.3s ease;
      }
      header.app-header:before {
        margin-left: 0;
      }
    }
    .app-sidebar__toggle .toogle_right {
      display: none;
    }
  }
  .sidebar_logo_img {
    display: none;
  }
  .mobile_toggle_right {
    display: none;
  }
  .app-nav {
    flex: 1 0 auto;
  }
  .app-header__logo {
    flex: 0 0 auto;
    display: block;
  }
  .app_header_block {
    padding: 24px 0;
    a {
      margin-bottom: 15px;
    }
  }
  .sidenav-toggled {
    .footer_content {
      width: 100%;
      margin-left: 0;
    }
    .app-header {
      margin-left: 85px;
    }
  }
  .footer_content {
    width: calc(100% - 300px);
    margin-left: 300px;
    box-shadow: 2px 2px 6px #000000;
    text-shadow: 2px 2px 4px #000000;
  }
  .sidebar_toggle_btn {
    margin-left: -23px;
    margin-top: -10px;
  }
}

.app_title {
  font-weight: 500;
  display: block;
  margin-top: 5px;
  span {
    font-size: 18px;
  }
  a {
    &:hover {
      color: #05367f;
    }
    &:focus {
      color: #05367f;
    }
  }
}

.left_Sidebar_Sec {
  position: fixed;
  top: 0;
  z-index: 2;
}

.left_sidebar_Content {
  float: left;
  width: 70px;
  height: 100vh;
  display: block;
  padding-top: 22px;
  position: relative;
  img {
    margin: 0 auto;
    display: block;
  }
  .top_user {
    margin-top: 60px;
    text-align: center;
    ul li {
      display: block;
      width: 100%;
      text-align: center;
      padding: 0 10px;
      margin-bottom: 12px;
      span,
      a {
        border-radius: 10px;
        padding: 12px 12px;
        display: block;
        font-size: $font-size-base;
        cursor: pointer;
        &:hover {
          opacity: 0.8;
        }
        &:focus img {
          opacity: 0.8;
        }
      }
    }
  }
}

.app-nav-right {
  width: auto;
  margin-left: auto;
  display: inline-block;
  ul li {
    display: inline-block;
    margin-left: 22px;
    &:last-child {
      margin-right: 0 !important;
    }
  }
}

.notification {
  position: relative;
  .notification_circle {
    width: 16px;
    height: 17px;
    border-radius: 100%;
    line-height: 17px;
    font-size: 11px;
    font-weight: 400;
    display: inline-block;
    text-align: center;
    position: absolute;
    right: -8px;
    top: 6px;
  }
}

.visible_notification {
  overflow: hidden;
  .layer_drop {
    opacity: 1;
    visibility: visible;
  }
  .notification_wrapper {
    right: 0;
    opacity: 1;
    visibility: visible;
  }
}

.layer_drop {
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  background: rgba(2, 16, 36, 0.45);
  visibility: hidden;
  z-index: 5;
  transition: all 0.5s ease;
}

.slice-btn {
  display: inline-block;
  position: relative;
  overflow: hidden;
  margin: 0 15px;
  padding: 0;
  font-size: 0;
  text-indent: -9999px;
  appearance: none;
  box-shadow: none;
  border-radius: 0;
  border: none;
  cursor: pointer;
  background: 0 0;
  outline: 0 !important;
  width: 26px;
  height: 26px;
  transition: all 0.4s ease;
  top: 2px;
  i {
    background: #4e4c5f;
    display: block;
    position: absolute;
    top: 12px;
    height: 2px;
    min-height: 2px;
    width: 100%;
    transition: all 0.4s ease;
  }
  i::after {
    background: #4e4c5f;
    position: absolute;
    display: block;
    left: 0;
    width: 100%;
    height: 2px;
    min-height: 2px;
    content: '';
    transition: all 0.4s ease;
    left: auto;
    right: 0;
    width: 50%;
    transition: all 0.4s ease;
    left: auto;
    right: 0;
    width: 75%;
    bottom: -8px;
  }
  i::before {
    background: #4e4c5f;
    position: absolute;
    display: block;
    left: 0;
    width: 100%;
    height: 2px;
    min-height: 2px;
    content: '';
    transition: all 0.4s ease;
    left: auto;
    right: 0;
    width: 50%;
    top: -8px;
    transition: all 0.4s ease;
    left: auto;
    right: 0;
    width: 50%;
  }
  &:hover {
    i::after {
      width: 100%;
      transition: all 0.4s ease;
      background: #05367f;
    }
    i::before {
      width: 100%;
      transition: all 0.4s ease;
      background: #05367f;
    }
    i {
      background: #05367f;
    }
  }
}

.app.sidenav-toggled {
  //.app-content {
  //  margin-left: 0;
  //}
  .app-sidebar {
    left: -230px;
  }
  .app-sidebar__overlay {
    visibility: hidden;
  }
  .app-header__logo {
    .small_logo {
      display: block;
      width: 28px;
    }
    .sidebar_logo_img {
      display: none;
    }
  }
}

a.app-menu__item img {
  vertical-align: middle;
}

.audit_item:hover {
  opacity: 0.5;
  transition: 0.3s ease-in-out 0.2s;
}

.left_sidebar_visible .slice-btn {
  i::before {
    transition: all 0.4s ease;
    left: 0;
    right: auto;
    width: 50%;
  }
  i::after {
    transition: all 0.4s ease;
    left: 0;
    right: auto;
    width: 75%;
  }
}
// header dropdown
.mat-menu-panel {
  border-radius: 0 0 4px 4px !important;
  margin-top: 5%;
}
@media (max-width: 2500px) {
  .app-content {
    padding: 70px 15px 30px;
  }
}
@media (max-width: 1440px) {
  .app-content {
    padding: 70px 15px 30px;
  }
}

@media (max-width: 991px) {
  .app-content {
    padding: 85px 15px 30px 15px !important;
    margin-left: 0;
    padding: 0 15px;
  }
  .footer_content {
    width: 100%;
    margin-left: 0;
  }
  .mobile_toggle_right {
    display: block;
    i {
      font-size: 20px;
      vertical-align: middle;
      margin-right: 10px;
    }
  }
  .app_header_block {
    padding: 0;
    a {
      margin-bottom: 0;
    }
  }
  .sidebar_logo_img {
    display: block;
    border-radius: 8px;
  }
  .app_title {
    padding-left: 10px;
  }
  .left_Sidebar_Sec {
    position: static;
  }
  .sidebar_toggle_btn {
    display: none;
  }
  .app-sidebar {
    float: left;
    position: static;
    height: 100vh;
  }
  .app-header {
    padding: 12px 15px;
    width: 100% !important;
    border-bottom: 1px solid #cccccc;
  }
  .app_header_deshktop {
    display: none;
  }
  .left_sidebar_visible {
    overflow: hidden;
    .layer-drop {
      opacity: 1;
      visibility: visible;
    }
    .left_sidebar_wrapper {
      left: 0;
      opacity: 1;
      visibility: visible;
    }
  }
  .layer-drop {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    background: rgba(2, 16, 36, 0.45);
    visibility: hidden;
    z-index: 5;
    transition: all 0.5s ease;
  }
  .left_sidebar_wrapper {
    position: fixed;
    left: -400px;
    top: 0;
    width: auto;
    max-width: 100%;
    height: 100vh;
    overflow-y: auto;
    z-index: 5;
    opacity: 0;
    transition: all 0.5s ease;
    visibility: hidden;
    .cross-icon {
      position: absolute;
      cursor: pointer;
      color: $white;
      font-size: 15px;
      font-weight: normal;
      right: 0;
      width: 27px;
      height: 27px;
      line-height: 27px;
      text-align: center;
      outline: none;
      z-index: 9;
      &:hover {
        background: #05367f;
      }
      &:focus {
        background: #05367f;
      }
    }
  }
  .top_heaer_right {
    transition: all 0.3s;
    position: relative;
    top: -7px;
  }
  .topheader_right_content {
    color: #000000;
    width: 100%;
    text-align: right;
    margin: auto;
    display: none;
    position: fixed;
    left: 0;
    right: auto;
    top: 60px;
    background: $white;
    border-bottom: 1px solid #cccccc;
    padding: 5px 24px 5px 5px;
  }
}

@media (max-width: 575px) {
  .notification_wrapper {
    width: 400px;
  }
  .app_title {
    margin-top: 7px;
    span a {
      display: none;
    }
  }
  .notification_list .notification_item {
    padding: 10px;
  }
}

@media (max-width: 480px) {
  .app_title {
    font-size: 17px;
    span {
      font-size: 12px;
    }
  }
  .notification_wrapper {
    width: 310px;
  }
}

@media (max-width: 340px) {
  .app-header .app_title {
    font-size: 14px;
    padding-left: 6px;
  }
}
