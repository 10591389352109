* {
    outline: 0;
  }
  
  .App {
    font-family: sans-serif;
    display: flex;
    /* text-align: center; */
    /* align-items: center; */
    flex-direction: column;
  }
  
  input {
    display: block;
    min-width: 90%;
    margin: 1em;
    padding: 1em;
    width: 35em;
    border-radius: 8px;
    border-style: none;
    border: 1px solid #e4e6e8;
    transition: 0.1s ease;
  }
  
  input:hover {
    border-color: palevioletred;
  }
  
  button {
    margin: 0.8em;
    padding: 1em;
    border: 1px solid #e4e6e8;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.1s ease-in;
  }
  
  button:hover {
    background-color: palevioletred;
    color: white;
  }
  