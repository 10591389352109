//colors

$primary: #0747A6;
$black:    #202125 !default;
$white:    #fff !default;
$gray:     #4A4A4A !default;
$blue:     #66afe9 !default;
$theme_univ: #16324F !default;
$theme_univ_hov: #224365 !default;
$theme_body_clr: #31323C !default;
$theme_danger: #ea4031 !default;
$blue-100: rgba(102,175,233,.35) !default;
$gray-100: #ccc;
$gray-400: #eee;
$black-150: rgba(0,0,0,.15);
$theme_link: #3E92CC;
$theme_link_hv: #2F3192;

// Fonts
$font-family-base:    'Poppins', sans-serif;
// stylelint-enable value-keyword-case
$font-size-base:      0.9375rem  !default; // Assumes the browser default, typically `16px`
$-h1: 2.25rem;
$-h2: 2rem;
$-h3: 1.75rem;
$-h4: 1.5rem;
$-h5: 1.25rem;
$-h6: 1.125rem;
$para-font: 1rem;


  // theme dark
$theme-gray: #5c616f;
$color-secondary: #2d323e;
