
/*--------------------------------------------------------------
## Custom Function
--------------------------------------------------------------*/
@import "./custom/functions";
/*--------------------------------------------------------------
## custom_variables
--------------------------------------------------------------*/
@import "./custom/custom_variables";
.light-theme {
  /*--------------------------------------------------------------
 ## primary variables
 --------------------------------------------------------------*/
  @import "./custom/primary_variables";
  /*--------------------------------------------------------------
  ## primary scss
  --------------------------------------------------------------*/
  @import "./custom/primary_theme";
}
.dark-theme {
  /*--------------------------------------------------------------
  ## Secondary variables
  --------------------------------------------------------------*/
  @import "./custom/secondary_variables";
  /*--------------------------------------------------------------
  ## Secondary scss
  --------------------------------------------------------------*/
  @import "./custom/secondary_theme";
}
/*--------------------------------------------------------------
## Default SASS Body
--------------------------------------------------------------*/
@import "./custom/reset";
/*--------------------------------------------------------------
## Custom SCSS
--------------------------------------------------------------*/
@import "./custom/custom";
/*--------------------------------------------------------------
## Button SCSS
--------------------------------------------------------------*/
@import "./custom/button_styles";
/*--------------------------------------------------------------
## sidebar SCSS
--------------------------------------------------------------*/
@import "./custom/sidebar_header";
/*--------------------------------------------------------------
## Toaster SCSS
--------------------------------------------------------------*/
@import "./custom/toaster";



