//colors

$light-theme: radial-gradient(circle, rgba(243,98,39,1) 0%, rgba(253,132,29,1) 50%, rgba(252,205,69,1) 100%);
$primary-text-color: radial-gradient(circle, rgba(243,98,39,1) 0%, rgba(253,132,29,1) 50%, rgba(252,205,69,1) 100%);
$side-nav-text: #202125;
$primary-color: radial-gradient(circle, rgba(243,98,39,1) 0%, rgba(253,132,29,1) 50%, rgba(252,205,69,1) 100%);
$primary-icn-bg: radial-gradient(circle, rgba(238,174,202,1) 0%, rgba(148,155,233,1) 100%);


// Fonts
$font-family-base: 'Poppins', sans-serif;
$font-size-base:  0.9375rem  !default; // Assumes the browser default, typically `16px`









